import React, {FormEvent, useState} from 'react';
import {Header} from "./components/Header";
import comingSoonLogo from './assets/img/coming-soon.png';
import {API_PATH} from './enviropment';

const App = () =>  {
  const [email,setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit =  (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("form submitted");
    fetch(API_PATH +'/newsletter', {
      method: "POST",
      body: JSON.stringify({
        "mail": email,
      })
    }).then( e => e.json())
    .then(e => e as Response)
    .then( e => {
      if ( e.status) {
        setMessage("success "+ e.message);
        setEmail("");
      }else {
        setMessage("error "+ e.message);
      }
    })
    .catch( e=> setMessage(e.toString()))
  }

  return (
      <div className="coming-soon-page">
        <Header/>

        <section className="coming-soon-area">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-10">
                <div className="coming-soon-content text-lg-left text-center">
                  <h2 className="title">Coming Soon</h2>
                  <form onSubmit={handleSubmit} className="newsletter-form-2">
                    <input type="email" placeholder="Enter Email Address" value={email} onChange={(e => setEmail(e.target.value))}/>
                    <button type="submit"><i className="fas fa-arrow-right"></i></button>
                  </form>
                  { message && <p style={{
                    marginTop: '1em',
                    marginLeft: '2em',
                    color: 'blue',
                    fontSize: 'small'
                  }}>{message}</p>}

                  <p className="newsletter-notice">Join With Us To Get More Update</p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-10">
                <div className="coming-soon-image text-right m-t-md-50">
                  <img src={comingSoonLogo} alt="coming soon"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
}

interface Response {
  status: boolean,
  message: string
}

export default App;
